import React, { createContext, useState, useEffect } from "react";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";
import { dataBase } from "../firebaseConfig";
import Loader from "../components/Loader";
import { getItems } from "../components/apiCrudRealTime";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [promo, setpromo] = useState();
  const [otro, setotro] = useState();
  const [categoriesPromo, setCategoriesPromo] = useState([]);
  const [categories, setCategories] = useState([]);
  const [shouldLoadFromFirestore, setshouldLoadFromFirestore] = useState(false);
  const itemsDataUrl = process.env.PUBLIC_URL + "/items.json";

  useEffect(() => {
    getItems()
      .then((categorias) => {
        const promoCategories = categorias.filter(
          (item) =>
            item.hasOwnProperty("categoria") &&
            [
              "redondos",
              "cuadrados",
              "eye cat",
              "lectura",
              "infantiles",
            ].includes(item.categoria)
        );

        const otherCategories = categorias.filter(
          (item) =>
            item.hasOwnProperty("categoria") &&
            ![
              "redondos",
              "cuadrados",
              "eye cat",
              "lectura",
              "infantiles",
            ].includes(item.categoria)
        );
        console.log(promoCategories);
        console.log(otherCategories);
        setCategoriesPromo(promoCategories);
        setCategories(otherCategories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const addToCart = (item) => {
    if (isInCart(item.id)) {
      sumarCantidad(item);
    } else {
      setCart([...cart, { ...item }]);
    }
  };

  const isInCart = (id) => {
    return cart.some((producto) => producto.id === id);
  };

  const sumarCantidad = (item, cantidad, formaPago) => {
    const cartActualizado = cart.map((producto) => {
      if (producto.id === item.id) {
        const productoActualizado = {
          ...producto,
          cantidad: cantidad,
          formaPago: formaPago,
        };
        return productoActualizado;
      } else {
        return producto;
      }
    });
    setCart(cartActualizado);
  };

  const clearCart = () => {
    setCart([]);
  };

  const clearProducto = (id) => {
    const cartFiltrado = cart.filter((producto) => producto.id !== id);
    setCart(cartFiltrado);
  };

  const totalCart = () => {
    const copyCart = [...cart];
    let acumulador = 0;
    copyCart.forEach((producto) => {
      acumulador += producto.cantidad;
    });
    return acumulador;
  };

  const totalPrecio = () => {
    const copyCart = [...cart];
    let acumulador = 0;
    copyCart.forEach((producto) => {
      acumulador += producto.cantidad * producto.precio;
    });
    return acumulador;
  };

  const cantidadSeleccionada = (id) => {
    const producto = cart.find((prod) => prod.id === id);
    return producto?.cantidad;
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const loadProductsFromFirestore = async () => {
    try {
      const itemsCollection = collection(dataBase, "items");
      const queryRef = query(
        itemsCollection,
        where("estado", "==", "activo"),
        orderBy("titulo", "asc")
      );
      const querySnapshot = await getDocs(queryRef);
      const productos = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      // Verifica si la URL del primer producto es válida
      const firstProductImageUrl = productos[0]?.imagenes[0]?.url;
      console.log(firstProductImageUrl);
      if (firstProductImageUrl) {
        checkIfValidImage(firstProductImageUrl)
          .then((isValidImage) => {
            if (isValidImage) {
              // Si la imagen es válida, establece los productos
              setItems(productos);
              setshouldLoadFromFirestore(true);
              setIsLoading(false);
            } else {
              // Si la imagen no es válida, carga desde el JSON
              loadProductsFromJson();
            }
          })
          .catch((error) => {
            console.error("Error al verificar la imagen:", error);
            loadProductsFromJson();
          });
      } else {
        // Si la URL es inválida o está ausente, carga desde el JSON
        loadProductsFromJson();
      }
    } catch (error) {
      console.error("Error al cargar desde Firestore:", error);
      loadProductsFromJson();
    }
  };

  // Función para verificar si una URL es válida
  const checkIfValidImage = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true); // La imagen se cargó correctamente
      };
      img.onerror = () => {
        resolve(false); // Hubo un error al cargar la imagen
      };
      img.src = url;
    });
  };

  const loadProductsFromJson = () => {
    fetch(itemsDataUrl)
      .then((response) => response.json())
      .then((data) => {
        // Resto del código para procesar los datos y ordenarlos por título
        const productos = data.sort((a, b) => {
          const tituloA = a.titulo.toLowerCase();
          const tituloB = b.titulo.toLowerCase();

          if (tituloA < tituloB) {
            return -1;
          }
          if (tituloA > tituloB) {
            return 1;
          }
          return 0;
        });

        setItems(productos);
        setIsLoading(false);
        console.log(productos);
      })
      .catch((error) => {
        console.error("Error al cargar desde JSON:", error);
        // Manejar el error según sea necesario
      });
  };

  useEffect(() => {
    loadProductsFromFirestore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Determina si cargar desde Firestore o JSON (ejemplo)

    if (shouldLoadFromFirestore) {
      getItems()
        .then((categorias) => {
          const promo = categorias.filter((item) => item.nombre === "promo");
          const otro = categorias.filter((item) => item.nombre === "otro");
          setpromo(promo[0].datos.url);
          setotro(otro[0].datos.url);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setpromo("/promo.jpg");
      setotro("/otro.jpg");
    }
  }, [shouldLoadFromFirestore]);

  // useEffect(() => {
  //   const loadProducts = async () => {
  //     const itemsCollection = collection(dataBase, "items");
  //     const queryRef = query(
  //       itemsCollection,
  //       where("estado", "==", "activo"),
  //       orderBy("titulo")
  //     );
  //     const querySnapshot = await getDocs(queryRef);
  //     const productos = querySnapshot.docs.map((doc) => {
  //       console.log("gaste una lectura desde el front");
  //       return { id: doc.id, ...doc.data() };
  //     });
  //     setItems(productos);
  //     setIsLoading(false);
  //   };

  //   loadProducts();
  // }, []);

  // useEffect(() => {
  //   const productos = itemsData;
  //   setItems(productos);
  //   setIsLoading(false);
  //   }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        clearCart,
        clearProducto,
        totalCart,
        totalPrecio,
        cantidadSeleccionada,
        handleModal,
        items,
        promo,
        otro,
        categoriesPromo,
        categories,
      }}
    >
      {isLoading ? (
        <div className="loader-container">
          <div className="loader">
            <h3 className="text-center mt-5">Cargando el catálogo</h3>
            <Loader />
          </div>
        </div>
      ) : (
        children
      )}
    </CartContext.Provider>
  );
};
