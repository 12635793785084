import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ItemList } from "./ItemList";
import Seo from "./Head";
import { CartContext } from "../context/CartContext";
import Player from "react-player";

const ItemListContainer = () => {
  window.scrollTo(0, 0);
  const { nombreCategoria } = useParams();
  const cartContext = useContext(CartContext);
  const { items } = cartContext;

  // useEffect(() => {
  //     const loadItems = async () => {
  //         const itemsCollection = collection(dataBase, 'items');
  //         const querySnapshot = await getDocs(itemsCollection);
  //         const productos = querySnapshot.docs.map((doc) => {
  //             console.log("gaste una lectura desde el front")
  //             return {
  //                 id: doc.id,
  //                 ...doc.data()
  //             }
  //         });
  //         const productosFiltrados = productos.filter((p) => p.stock > 0 && p.imagenes.length > 0);
  //         const productosOrdenados = [...productosFiltrados].sort((a, b) => (a.precio > b.precio ? 1 : a.precio < b.precio ? -1 : 0));
  //         setItems(productosOrdenados);
  //         setIsLoading(false);
  //     };

  //     loadItems();
  // }, []);

  const productosCategoria =
    nombreCategoria !== "todos"
      ? items.filter((p) => p.categoria === nombreCategoria)
      : items;

  const urlParts = window.location.pathname.split("/");
  const lastUrlPart = urlParts[urlParts.length - 1];

  return (
    <>
      <Seo
        title={"Catálogo"}
        description={"Recetados, Sol, Contactología"}
        image={`${window.location.origin}%PUBLIC_URL%/logoVioleta.jpg`}
        pathSlug={window.location.href}
      />

      <div className="item row">
        <div
          className={`col-6 col-md-3 card ${
            lastUrlPart !== "irrompible" ? "d-none" : ""
          }`}
        >
          <Player
            url="http://drive.google.com/uc?export=view&id=1ozbuQd-R4vVVPJINLkcc2QDfy7_UmsMb"
            muted={true}
            playing={true}
            loop={true}
            width={"600"}
            height={"400"}
            // style={{ marginTop: 100, marginBottom: 40 }}
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="irrompible"
          />
        </div>
        <div
          className={`col-6 col-md-3 card ${
            lastUrlPart !== "sol" ? "d-none" : ""
          }`}
        >
          {" "}
          <Player
            url="http://drive.google.com/uc?export=view&id=1Nh1wA7camS_h6_TxBJrBPPvOG4pUW5Xt"
            muted={true}
            playing={true}
            loop={true}
            width={"600"}
            height={"400"}
            // style={{ marginTop: 100, marginBottom: 40 }}
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="irrompible"
          />
        </div>

        <ItemList items={productosCategoria} />
      </div>
    </>
  );
};

export default ItemListContainer;
