import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext";

const DetailPromo = () => {
  window.scrollTo(0, 0);

  const cartContext = useContext(CartContext);
  const { items } = cartContext;

  const { categoriesPromo } = cartContext;

  return (
    <>
      <div className="nuevoHome container-fluid sinBorde ">
        <div className="row  mt-3">
          <div className=" col-12">
            <div id="links" className="botones row">
              {categoriesPromo.map((cat) => (
                <div key={cat.id} className="col-6 col-md-3">
                  <Link
                    to={`/categoria/${cat.categoria}`}
                    className="  row promoHome"
                  >
                    <div className="sombraCat">
                      <img
                        src={
                          items.find((item) => item.categoria === cat.categoria)
                            .imagenes[0].url
                        }
                        alt="promo"
                        className="col-12 img-fluid  fondoGris"
                      />
                      <h1 className="textoBajo text-center">
                        {" "}
                        {cat.categoria}{" "}
                      </h1>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailPromo;
