import { useState, useEffect } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { dataBase } from "../firebaseConfig";
import Modal from "react-bootstrap/Modal";
import ImagenUpload from "./ImageUpload";
import SelectCategoria from "./SelectCategoria";
import { storage } from "../firebaseConfig";
import {
  getMetadata,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const Edit = ({ productData }) => {
  const [categoria, setCategoria] = useState("");
  const [titulo, setTitulo] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [medidas, setMedidas] = useState({ ancho: "", alto: "", patilla: "" });
  const [imagenes, setImagenes] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const [stock, setStock] = useState(0);

  const subirImagenes = async (img, borrar) => {
    if (borrar === 1) {
      const borrarUnaImagen = imagenes.filter((imagen) => imagen.name !== img);
      setImagenes(borrarUnaImagen);
    } else {
      const storageRef = ref(storage, `/files/${img.name}`);
      try {
        const metadata = await getMetadata(storageRef);
        console.log(`La imagen ${img.name} ya existe en el almacenamiento.`);
        // La imagen ya existe en el almacenamiento, agrega la URL directamente
        const url = await getDownloadURL(storageRef);
        setImagenes((prevImages) => [...prevImages, { name: img.name, url }]);
      } catch (error) {
        if (error.code === "storage/object-not-found") {
          try {
            await uploadBytes(storageRef, img);
            const url = await getDownloadURL(storageRef);
            setImagenes((prevImages) => [
              ...prevImages,
              { name: img.name, url },
            ]);
            console.log(`Imagen ${img.name} subida y añadida al estado.`);
          } catch (uploadError) {
            console.error("Error uploading image:", uploadError);
          }
        } else {
          console.error("Error getting metadata:", error);
        }
      }
    }
  };

  const update = async (e) => {
    e.preventDefault();
    const product = doc(dataBase, "items", productData.id);
    const data = {
      categoria,
      titulo,
      descripcion,
      proveedor,
      medidas,
      imagenes,
      stock,
      estado: imagenes.length === 0 || stock === 0 ? "pausado" : "activo",
    };
    await updateDoc(product, data);
    console.log("Producto actualizado con éxito.");
  };

  useEffect(() => {
    if (productData) {
      setImagenes(productData.imagenes || []);
      setCategoria(productData.categoria || "");
      setTitulo(productData.titulo || "");
      setDescripcion(productData.descripcion || "");
      setProveedor(productData.proveedor || "");
      setMedidas(productData.medidas || { ancho: "", alto: "", patilla: "" });
      setStock(productData.stock || 0);
      console.log("Datos del producto cargados en el estado.");
    }
  }, [productData]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const cargarCategoria = (categoria) => {
    setCategoria(categoria);
  };

  const handleCategoriaChange = (value) => {
    setCategoria(value);
    cargarCategoria(value);
  };

  return (
    <>
      <div className="" onClick={handleShow}>
        <i className="btnCant bi bi-pencil-square"></i>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="mb-3 row cajaUpload">
                  {[0, 1, 2, 3].map((index) => (
                    <div className="col-4 text-center" key={index}>
                      <ImagenUpload
                        updateFile={imagenes[index] || { name: "", url: "" }}
                        subirImagenes={subirImagenes}
                      ></ImagenUpload>
                    </div>
                  ))}
                </div>
                <form onSubmit={update} className="row">
                  <div className="mb-3 col-12 d-flex">
                    <label className="form-label col-4">Categoria</label>
                    <SelectCategoria
                      categoria={categoria}
                      cargarCategoria={handleCategoriaChange}
                    />
                  </div>
                  <div className="mb-3 col-12 d-flex">
                    <label className="form-label col-4">Titulo</label>
                    <input
                      value={titulo}
                      onChange={(e) => setTitulo(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-12 d-flex">
                    <label className="form-label col-4">Descripcion</label>
                    <input
                      value={descripcion}
                      onChange={(e) => setDescripcion(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-12 d-flex">
                    <label className="form-label col-4">Proveedor</label>
                    <input
                      value={proveedor}
                      onChange={(e) => setProveedor(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-12 col-md-4 text-center">
                    <label className="form-label">Stock</label>
                    <div className="input-group">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() => setStock(stock - 1)}
                      >
                        -
                      </button>
                      <input
                        value={stock}
                        onChange={(e) => setStock(parseInt(e.target.value, 10))}
                        type="number"
                        className="form-control text-center"
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() => setStock(stock + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="col-0 col-md-2"></div>
                  <div className="mb-3 col-4 col-md-2 text-center">
                    <label className="form-label">Ancho</label>
                    <input
                      value={medidas.ancho}
                      onChange={(e) =>
                        setMedidas({ ...medidas, ancho: e.target.value })
                      }
                      type="number"
                      className="form-control text-center"
                    />
                  </div>
                  <div className="mb-3 col-4 col-md-2 text-center">
                    <label className="form-label">Alto</label>
                    <input
                      value={medidas.alto}
                      onChange={(e) =>
                        setMedidas({ ...medidas, alto: e.target.value })
                      }
                      type="number"
                      className="form-control text-center"
                    />
                  </div>
                  <div className="mb-3 col-4 col-md-2 text-center">
                    <label className="form-label">Patilla</label>
                    <input
                      value={medidas.patilla}
                      onChange={(e) =>
                        setMedidas({ ...medidas, patilla: e.target.value })
                      }
                      type="number"
                      className="form-control text-center"
                    />
                  </div>

                  <button
                    type="submit"
                    onClick={handleClose}
                    className="btn btn-primary"
                  >
                    Actualizar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Edit;
